
import { useRoute, useRouter } from 'vue-router'
import { ref, watch, defineAsyncComponent, Ref, UnwrapRef, provide } from 'vue'
import { Course, CourseDetail, createCourseDetail } from 'momai'
import { listByCourseSeriesId } from '@/api/entity/course'
import { activeTab, detailCourseSeries, detailCourseSeriesId, courseSeriesPrice, salePrice, magicBeanPrice } from './../course-list/state'
import Pay from '../Pay.vue'
import CommentsDo from './pingluncomponent/CommentsDo.vue'
import { hasBoughtCourseSeries } from '@/api/user/student'
import { showtip } from '@/views/sales/product/useShowTip'
import { changeShareInfo } from '@/utils/wechat'
import { getUserId } from '@/utils/auth'
import { api } from '@/api/useAxios'
import useFetchDetail from './useFetchDetail'
import useSendReview from './useSendReview'
import MomaiCourseListNewVue from '@/components/MomaiCourseListNew.vue'
import DoWork from './doworkcomponent/DoWork.vue'
import WorkList from './component/WorkList.vue'
import MomaiSelectTitle from '@/components/Momai/MomaiSelectTitle.vue'
import { Dialog } from 'vant'

export default {
  components: {
    Pay,
    MomaiSelectTitle,
    Comments: defineAsyncComponent(() => import('./pingluncomponent/Comments.vue')),
    CourseDesc: defineAsyncComponent(() => import('./component/CourseDesc.vue')),
    WorkDesc: defineAsyncComponent(() => import('./component/WorkDesc.vue')),
    RecommendCourse: defineAsyncComponent(() => import('@/views/home/components/RecommendCourse.vue')),
    RecommendTeacher: defineAsyncComponent(() => import('@/views/home/components/RecommendTeacher.vue')),
    CommentsDo,
    WorkList,
    DoWork
  },
  setup: () => {
    const tabList = Object.freeze([
      { id: 0, label: '简介' },
      { id: 1, label: '练习' } 
      // { id: 2, label: '其他推荐' }
    ])
    let titleactiveTab: Ref<UnwrapRef<number>> = ref(0)
    

    const route = useRoute()
    const { courseSeriesId } = route.params
    const activeTab = route.query?.activeTab
    if(activeTab){
      titleactiveTab.value = 1
    }
    const pay = ref<typeof Pay | null>(null)
    const courseList = ref<Course[]>([])
    const router = useRouter()
    //目录相关 start
    const videoSrc = ref('')
    const videoCoverSrc = ref('')
    const workRef = ref<typeof MomaiCourseListNewVue | null>(null)
    const courseRef = ref<typeof MomaiCourseListNewVue | null>(null)
    const courseDetail = ref<CourseDetail>(createCourseDetail())
    const coursecheck = (courseId: any) => {
      api.post('/courseinfo/info/detailnew', {
        courseInfoId: courseId
      }).then(r => {
        const courselistNew = courseRef.value
        if (courselistNew) {
          courselistNew.changechoosestauts(courseId)
        }
        courseDetail.value = r
        videoSrc.value = courseDetail.value.video
        videoCoverSrc.value = courseDetail.value.videoCover
      })
      cleanwork()
    }
    const workcheck = (courseId: any) => {
      api.post('/courseinfo/info/detailnew', {
        courseInfoId: courseId
      }).then(r => {
        const worklist = workRef.value
        if (worklist) {
          worklist.changechoosestauts(courseId)
        }
        courseDetail.value = r
        videoSrc.value = courseDetail.value.exampleVideo
        videoCoverSrc.value = courseDetail.value.exampleVideoCover
      })
      cleancourse()
    }
    const cleanwork = () => {
      const workinfo = workRef.value
        if (workinfo) {
          workinfo.resetAll()
        }
    }
    const cleancourse = () => {
      const courselist = courseRef.value
        if (courselist) {
          courselist.resetAll()
        }
    }
    //目录相关 end

    const checkedid = ref()
    const fetchCourseList = () => {
      const cs = detailCourseSeries.value
      listByCourseSeriesId(Number(courseSeriesId)).then(list => {
        courseList.value = list
        if(list){
          const gocourseId = route.query?.courseId
          if(gocourseId){
            checkedid.value = gocourseId
          }else{
            checkedid.value = list[0]?.id //默认查第一个课程
          }
          const activeTab = route.query?.activeTab
          if(activeTab && Number(activeTab) === 1){
            workcheck(checkedid.value)
          }else{
            coursecheck(checkedid.value)
          }
          
        }
      })
      changeShareInfo({
        title: cs.courseName,
        desc: process.env.VUE_APP_PAGE_DESC,
        imgUrl: cs.img,
        link: `${process.env.VUE_APP_REDIRECT_URI}/coursedetail/${cs.id}?referrer=${getUserId()}`
      })
    }
    const hasBought = ref(false)
    const resetHasBought = () => {
      hasBoughtCourseSeries(Number(courseSeriesId)).then(r => {
        hasBought.value = r
      })
    }
    resetHasBought()

    detailCourseSeriesId.value = Number(courseSeriesId)
    if (detailCourseSeries.value) {
      fetchCourseList()
    }

    watch(detailCourseSeries, fetchCourseList)

    const { courseSeriesAllInfo, reviewPageData } = useFetchDetail({ courseSeriesId })
    const comRef = ref<typeof CommentsDo | null>(null)
    const openpopup = () => {
      const popup = comRef.value
        if (popup) {
          popup.openpopup()
        }
    }
    const doworkRef = ref<typeof DoWork | null>(null)
    const opendowork = () => {
      if(!hasBought.value){
        Dialog.alert({
          message: '购买课程后才能提交练习哦！'
        })
        return false
      }
      const workpopup = doworkRef.value
        if (workpopup) {
          workpopup.openpopup()
        }
    }

    const courseId = Number(courseSeriesId)
    const activeName = ref('a')

    const tabactive = ref(0)

    const worklistRef = ref<typeof WorkList | null>(null)
    const reloadmywork = () => {
      const worklistpopup = worklistRef.value
        if (worklistpopup) {
          worklistpopup.reset2()
          tabactive.value = 1

        }
    }
    provide('tabactive', tabactive)

    const changeTab = (_activeTab: number) => {
      titleactiveTab.value = _activeTab
      if(_activeTab == 0){
        coursecheck(courseDetail.value.id)
      }else if(_activeTab == 1){
        workcheck(courseDetail.value.id)
      }
    }

    return {
      tabList, changeTab, titleactiveTab,
      activeName,
      courseId,
      videoSrc,
      videoCoverSrc,
      ...useSendReview({ courseSeriesId }),
      comRef,
      workRef,
      doworkRef,
      worklistRef,
      courseRef,
      openpopup,
      opendowork,
      coursecheck,
      workcheck,
      courseDetail,
      pay,
      showtip,
      activeTab,
      courseSeriesPrice,
      salePrice,
      magicBeanPrice,
      couseSeriesDetail: detailCourseSeries,
      courseSeriesAllInfo,
      reviewPageData,
      courseList,
      checkedid,
      //...useHasBoughtCourseSeries(Number(courseSeriesId)),
      openPay: () => {
        const payInstance = pay.value
        if (payInstance) {
          payInstance.openPay()
        }
      },
      // changetab: (name:any) => {
      //   if(name === 'a'){
      //     coursecheck(courseDetail.value.id)
      //   }else{
      //     workcheck(courseDetail.value.id)
      //   }
      // },
      reloadmywork,
      goBack: () => {
        router.go(-1)
      },
      resetHasBought,
      hasBought
    }
  }
}
