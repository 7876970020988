
import { ref, reactive } from 'vue'
import MomaiSelectTitle from '@/components/Momai/MomaiSelectTitle.vue'
import TeacherList from '@/views/teacher/components/TeacherList.vue'
import { Page, Tag, MmTeacher } from 'momai'
import { getTeacherList } from '@/api/user/teacher'
import { selectByType } from '@/api/entity/tag'
import { getCompanyId } from '@/utils/auth'

export default {
  components: {
    MomaiSelectTitle,
    TeacherList
  },
  setup: () => {
    const teacherTypeList = ref<Tag[]>([])
    const teacherList = ref<MmTeacher[]>([])
    const teacherTag = ref<number>(0)
    const active = ref(0)
    const state = reactive({
      loading: false,
      finished: false,
      refreshing: false
    })
    const reviewPageData = reactive<Page>({
      current: 1,
      size: 10
    })
    const backToTop = () => {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
    const onLoad = () => {
      if (teacherTypeList.value.length < 1) {
        teacherList.value = []
        selectByType(3).then(r => {
          teacherTypeList.value = r
          teacherTag.value = r[0].id
          getTeacherList({
            teacherType: teacherTag.value.toString(),
            hot: active.value === 1 ? '1' : '',
            ...reviewPageData,
            companyId:getCompanyId()
          }).then((r: any) => {
            if (state.finished) {
              return
            }
            if (state.refreshing) {
              teacherList.value = []
              state.refreshing = false
            }
            teacherList.value.push(...r.records)
            reviewPageData.current += 1
            if (teacherList.value.length >= r.total) {
              state.finished = true
            }
            state.loading = false
          })
        })
      } else {
        getTeacherList({
          teacherType: teacherTag.value.toString(),
          hot: active.value === 1 ? '1' : '',
          ...reviewPageData,
          companyId:getCompanyId()
        }).then((r: any) => {
          if (state.finished) {
            return
          }
          if (state.refreshing) {
            teacherList.value = []
            state.refreshing = false
          }
          teacherList.value.push(...r.records)
          reviewPageData.current += 1
          if (teacherList.value.length >= r.total) {
            state.finished = true
          }
          state.loading = false
        })
      }
    }
    onLoad()
    const refreshTeacher = () => {
      state.finished = false
      reviewPageData.current = 1
      state.loading = true
      onLoad()
    }

    const onClick = () => {
      backToTop()
      teacherList.value = []
      state.finished = false
      reviewPageData.current = 1
      state.loading = true
      onLoad()
    }
    return {
      active,
      teacherList,
      teacherTypeList,
      teacherTag,
      onClick,
      state,
      onLoad,
      refreshTeacher
    }
  }
}
