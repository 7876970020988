
  import Recorder from 'recorder-core/recorder.mp3.min'
  import 'recorder-core/src/extensions/frequency.histogram.view'
  import 'recorder-core/src/extensions/lib.fft.js'
  import { uploadImage } from '@/api/universal/file'
  import { defineComponent, onMounted, ref } from 'vue';
  import { Dialog, Toast } from 'vant';
  export default defineComponent({
    name: 'UploadAudio',
    props: {
      voice: {
        type: String
      },
      savedir: {
        type: String,
        default: 'homework-user-'
      }

    },
    setup(props, { emit }) {
      const waveDom = ref<HTMLDivElement | null>(null)
      let wave: any = null
      let recorder: any = null
      const btnText = ref('开始录音')
      const audioUrl = ref(props.voice)

      const openRecorder = () => new Promise((resolve) => {
        recorder.open(() => {
          if (!wave) {
            wave = Recorder.FrequencyHistogramView({ elem: waveDom.value })
          }
          resolve(true)
        }, (msg: string, isNotAllow: boolean) => {
          if (isNotAllow) {
            Toast.fail('打开录音失败！')
          }
        })
      })
      const resetRecorder = () => {
        recorder.close()
        if (waveDom.value) {
          waveDom.value.innerHTML = ''
          wave = Recorder.FrequencyHistogramView({ elem: waveDom.value })
        }
        btnText.value = '开始录音'
      }

      onMounted(() => {
        recorder = Recorder({
          type: 'mp3',
          bitRate: 16,
          sampleRate: 16000,
          onProcess: (buffers: any, powerLevel: any, bufferDuration: any, bufferSampleRate: any) => {
            if (wave) {
              wave.input(buffers[buffers.length - 1], powerLevel, bufferSampleRate)
            }
          }
        })
        openRecorder()
      })

      return {
        btnText,
        waveDom,
        audioUrl,
        startRecord: () => {
          if (audioUrl.value) {
            Dialog.confirm({
              title: '确认',
              message: '已存在录音，是否需要删除重新录音？'
            }).then(() => {
              audioUrl.value = ''
              emit('update:voice', '')
              if (recorder) {
                openRecorder().then(() => {
                  recorder.start()
                  btnText.value = '录音中'
                })
              }
            }).catch(() => {})
          } else {
            if (recorder) {
              openRecorder().then(() => {
                recorder.start()
                btnText.value = '录音中'
              })
            }
          }
        },
        endRecord: () => {
          recorder.stop((blob: Blob) => {
            uploadImage(props.savedir, new File([blob], '录音.mp3')).then(r => {
              audioUrl.value = r
              emit('update:voice', r)
            })
            resetRecorder()
          }, (msg: string) => {
            resetRecorder()
            Toast.fail('录音失败:' + msg)
          })
        },
        askDel: () => {
          Dialog.confirm({
            title: '确认',
            message: '确认删除录音吗？'
          }).then(() => {
            audioUrl.value = ''
            emit('update:voice', '')
          }).catch(() => {})
        }
      }
    }
  })
