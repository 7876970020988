
  import { defineComponent, reactive, ref } from 'vue';
  import useUploadVideo from '@/utils/useUploadVideo'

  export default  defineComponent({
    name: 'UploadVideo',
    props: {
      video: {
        type: String
      },
      videoCover: {
        type: String
      },
      savedir: {
        type: String,
        default: 'homework-user-'
      }
    },
    setup(props, {emit}) {
      const videoUploader = ref<HTMLInputElement | null>(null)
      const uploadDirectory = String(props.savedir)
      const commentVideoInfo = reactive({
        videoUrl: props.video,
        coverUrl: props.videoCover,
        duration: 0
      })
      const delVedio = () => {
        commentVideoInfo.videoUrl = ''
        commentVideoInfo.coverUrl = ''
        commentVideoInfo.duration = 0
      }
      return {
        videoUploader,
        commentVideoInfo,
        commentVideo: useUploadVideo({
          picker: videoUploader,
          uploadDirectory,
          uploadFinished: (videoUrl, coverUrl, duration) => {
            commentVideoInfo.videoUrl = videoUrl.value
            commentVideoInfo.coverUrl = coverUrl.value
            commentVideoInfo.duration = duration.value
            emit('update:video', videoUrl.value)
            emit('update:videoCover', coverUrl.value)
          }
        }),
        delVedio
      }
    }
  })
