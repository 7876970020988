
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { api } from '@/api/useAxios'
import { FetchFunction, useLoadList } from '@/utils/useLoadList'
import { Review } from 'momai'
import { getUserId } from '@/utils/auth'
import { Toast } from 'vant'
import { useFetchDetail as useFetchHomeworkDetail, update } from '@/api/entity/homework'
import { likeOrUnlike } from '@/api/likeOrUnlike'
import { changeShareInfo } from '@/utils/wechat'

export default {

  setup: () => {
    const route = useRoute()
    const { homeworkId } = route.params
    const { needChangeReadStatus } = route.query
    const { homework } = useFetchHomeworkDetail(homeworkId, () => {
      changeShareInfo({
        title: homework.value.workName,
        desc: '导师点评：' + homework.value.teaEva,
        link: window.location.href,
        imgUrl: homework.value.videoCover
      })
    })
    const teacherunAudit = computed(() => {
      const h = homework.value
      return !h.teaEva && !h.teaVoice && !h.teaPic
    })
    const studentComments = ref<Review[]>([])
    const totalNum = ref(-1)
    const fetchComments: FetchFunction = (pageData, loadEnd) => {
      api.post('/mmpinglun/getPjPageInfo', {
        homeworkId,
        ...pageData,
        pjType: 1,
        ordId: homeworkId
      }).then(({ records, total }) => {
        studentComments.value.push(...records)
        totalNum.value = total
        loadEnd()
      })
    }
    if (needChangeReadStatus === 'true') {
      update({
        id: homeworkId,
        unread: 1
      })
    }

    const {
      loadMore,
      loading,
      finished
    } = useLoadList({
      fetch: fetchComments,
      total: totalNum,
      list: studentComments
    })

    return {
      teacherunAudit,
      likeOrUnlike,
      studentComments,
      homework,
      loadMore,
      loading,
      finished,
      sendReview: (info: string, reset: () => void) => {
        api.post('/mmpinglun/save', {
          info,
          ordId: homeworkId,
          status: 0,
          type: 1,
          userId: getUserId()
        }).then(() => {
          reset()
          Toast.success('评论成功，待审核！')
        })
      }
    }
  }
}
