
import { ref } from 'vue'
import { api } from '@/api/useAxios'
import { Course } from 'momai'
export default {

  setup: () => {
    const myCollects = ref<Course[]>([])

    api.get('/courseinfo/qryMyShouCangCourseInfos').then(r => {
      myCollects.value = r
    })

    return {
      myCollects
    }
  }
}
