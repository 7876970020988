
import useLikeOrCollect from './useLikeOrCollect'
import { defineComponent, PropType, ref, toRefs, onMounted } from 'vue'
import { CourseSeriesDetail, CourseDetail } from 'momai'

export default defineComponent({
  props: {
    courseSeriesDetail: {
      type: Object as PropType<CourseSeriesDetail>,
      required: true
    },
    courseDetail: {
      type: Object as PropType<CourseDetail>,
      required: false
    }
  },
  setup: (props, { emit }) => {
    const boxshow: any = ref(false)
    const message = ref('详情')
    const { courseSeriesDetail } = toRefs(props)

    return {
      ...useLikeOrCollect({
        courseSeriesDetail
      }),
      boxshow,
      message,
      togglebox: () => {
          boxshow.value = !boxshow.value;
          if(boxshow.value){
            message.value = '隐藏'
          }else{
            message.value = '详情'
          }
      },
      showpopup: ()=> {
        emit('showpopup')
      }

    }
  }
})
