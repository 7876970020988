
import { defineComponent, inject, PropType, reactive, Ref, ref, watch, watchEffect } from 'vue'
import { Page } from 'momai'
import { api } from '@/api/useAxios'
import WorkItem from './WorkItem.vue'
import Step from './Step.vue'

import { useLoadList, FetchFunction } from '@/utils/useLoadList'
import { myLoadList, FetchFunction2 } from './MyWorkLoadList'
import { CourseDetail } from 'momai'

export default defineComponent({
  name:"workListVue",
  components: {
    WorkItem,
    Step
  },
  props: {
    courseId: {
      type: Number,
      required: true
    },
    courseDetail: {
      type: Object as PropType<CourseDetail>,
      required: false
    }
  },
  setup: (props) => {
    const reviewPageData = reactive<Page>({
      current: 1,
      size: 10
    })
    const watchCourseType = ref(1)
    const watchMyType = ref(1)
    const otherWorks = ref<any>([])
    const total = ref()
    const fetch: FetchFunction = (pageData, loadEnd) => {
      api.post('/homework/getOtherStuPageWorksNew', {
        courseId: props.courseId,
        courseDetailId: watchCourseType.value == 0 ? '' : props.courseDetail?.id,
        ...reviewPageData
      }).then(r => {
        otherWorks.value.push( ...r.records)
        total.value = r.total
        loadEnd()
      })
    }
    
    const reviewPageData2 = reactive<Page>({
      current: 1,
      size: 10
    })
    const myWorks = ref<any>([])
    const total2 = ref()
    const fetch2: FetchFunction2 = (pageData, loadEnd) => {
      api.post('/homework/getOtherStuPageWorksNew', {
        onlyMine: '1',
        courseId: props.courseId,
        courseDetailId: watchMyType.value == 0 ? '' : props.courseDetail?.id,
        ...reviewPageData2
      }).then(r => {
        myWorks.value.push( ...r.records)
        total2.value = r.total
        loadEnd()
      })
    }

    const { loadMore,reset, ...otherLoadProps } = useLoadList({
      pageData: reviewPageData,
      list: otherWorks,
      total,
      fetch
    })

    const { loadMore2,reset2, ...otherLoadProps2 } = myLoadList({
      pageData: reviewPageData2,
      list: myWorks,
      total2,
      fetch2
    })

    watch(watchCourseType, () => {
      reset()
    })
    watch(watchMyType,() => {
      reset2()
    })
    const resetinfo = () => {
      if(watchCourseType.value === 1){
        reset()
      }
      if(watchMyType.value === 1){
        reset2()
      }
    }
    watch(() => props.courseDetail, resetinfo, {
      deep: true
    })

    const active = inject<Ref>('tabactive')!

    return {
      active,
      total,
      total2,
      otherWorks,
      myWorks,
      loadMore: loadMore,
      loadMore2: loadMore2,
      ...otherLoadProps,
      ...otherLoadProps2,
      watchCourseType,
      watchMyType,
      reset2
    }
  }
})
