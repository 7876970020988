
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'TeacherList',
  props: {
    teacherList: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const router = useRouter()
    return {
      gotoPage: (e: any) => {
        router.push({
          path: '/teacher/detail/'+e.userId
        })
      }
    }
  }
})
