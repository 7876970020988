

import { useRoute } from 'vue-router'
import { ref, reactive, defineAsyncComponent, computed } from 'vue'
import { getAllProductInfo, saveOrder } from '@/api/sales'
import { ProductVo, buildProductVo, OrderInfo, buildOrderInfo } from 'momai'
import { share } from '@/states/sales'
import productForm from '../components/ProductForm.vue'
import qs from 'qs'
import { formatDate } from '@/utils/format'
import { showtip } from './useShowTip'
import MomaiSelectTitle from '@/components/Momai/MomaiSelectTitle.vue'

export default {
  components: { productForm, MomaiSelectTitle, PayForm: defineAsyncComponent(() => import('../components/PayForm.vue')) },
  setup: () => {
    const title = '活动详情'
    const { referrer } = qs.parse(window.location.search.replace(/\?/, ''))
    const { productId } = useRoute().params
    const curProduct = ref<ProductVo>(buildProductVo())
    // 是否过期
    const producExpired = computed(() => formatDate(new Date()) > curProduct.value.expireTime)
    // 报名人数是否已满
    const productMemberLimited = computed(() => curProduct.value.memberLimit <= curProduct.value.members.length)
    const hasAttend = computed(() => curProduct.value.hasAttend)
    const orderInfo = ref<OrderInfo>(buildOrderInfo())
    const qryProductInfo = () => {
      const id = productId + ''
      getAllProductInfo(id).then(r => {
        curProduct.value = r
        share(curProduct.value)
      })
    }
    if (productId != null) {
      qryProductInfo()
    }
    const params = reactive({
      show: false,
      step: 0
    })
    const resetProductInfo = () => {
      qryProductInfo()
    }
    resetProductInfo()
    const submitFn = () => {
      if (orderInfo.value.id === '') {
        orderInfo.value.productId = curProduct.value.id
        orderInfo.value.payFee = orderInfo.value.fee = curProduct.value.fee
        if (referrer != null) {
          orderInfo.value.referrer = referrer
        }
        saveOrder(orderInfo.value).then(r => {
          orderInfo.value.id = r
          params.step = 1
          params.show = true
        }).catch(() => {
          params.show = false
        })
      } else {
        params.show = true
        params.step = 1
      }
    }
    return {
      title,
      producExpired,
      productMemberLimited,
      hasAttend,
      resetProductInfo,
      curProduct,
      submitFn,
      params,
      orderInfo,
      showtip
    }
  }
}

