import { api } from '@/api/useAxios'
import { Toast } from 'vant'
import { getUserId } from '@/utils/auth'

const useSendReview = ({
  courseSeriesId
}: {
  courseSeriesId: string | string[];
}) => {
  return {
    sendReview: (info: string, reset: () => void) => {
      api.post('/mmpinglun/save', {
        type: 0,
        ordId: courseSeriesId,
        status: 0,
        userId: getUserId(),
        info
      }).then(() => {
        reset()
        Toast.success('评论成功，待审核！')
      })
    }
  }
}

export default useSendReview
