
import { defineComponent, PropType } from "vue";
import { timeFormat } from '@/utils/util'
import { Homework } from "momai";

export default defineComponent({
  props: {
    otherworks: Array as PropType<Homework[]>,
    showSelf: {  //是否展示公开属性
      type: Boolean,
      default: false
    }
  },
  setup () {
    return {
      timeFormat
    }
  }
});
