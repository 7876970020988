
import { reactive,toRefs,onBeforeMount,onMounted,ref} from 'vue'
interface DataProps {}
export default {
    name: '',
      setup() {
          console.log('1-开始创建组件-setup')
          const data: DataProps = reactive({

          })
          onBeforeMount(() => {
              console.log('2.组件挂载页面之前执行----onBeforeMount')
          })
          onMounted(() => {
              console.log('3.-组件挂载到页面之后执行-------onMounted')
          })
          const refData = toRefs(data);
          const show = ref(true)
          const activeName = ref('b')
          return {
              ...refData,
              show,
              activeName
          }

      }
  };
