
import { ref, reactive } from 'vue'
import MomaiSelectTitle from '@/components/Momai/MomaiSelectTitle.vue'
import { api } from '@/api/useAxios'
import ActivityList from '@/views/sales/components/ActivityList.vue'
import { Page, Product } from 'momai'
import { queryActivityByType } from '@/api/sales'

interface ImgProps {
    id: string;
    imgUrl: string;
    imgLink: string;
}
export default {
  components: {
    MomaiSelectTitle,
    ActivityList
  },
  setup: () => {
    const activityList = ref<Product[]>([])
    const state = reactive({
      loading: false,
      finished: false,
      refreshing: false
    })
    const reviewPageData = reactive<Page>({
      current: 1,
      size: 10
    })
    const imgUrls = reactive<ImgProps[]>([])
    api.get('/tag/listAllByType/4').then(resp => {
      const result = resp.map((item: any) => {
        const img: ImgProps = { id: '', imgUrl: '', imgLink: '' }
        img.id = item.id
        img.imgUrl = item.tagName
        img.imgLink = item.rsrv1
        return img
      })
      imgUrls.push(...result)
    })

    const handleDetail = (index: number) => {
      const linkUrl: string = imgUrls[index].imgLink
      if (linkUrl) window.location.href = linkUrl
    }
    const title = '推荐活动'
    const active = ref(0)
    const activityType = ref(0)
    const dateList = [
      {
        id: 0,
        label: '课程促销',
        value: 0
      },
      {
        id: 1,
        label: '线上活动',
        value: 1
      },
      {
        id: 2,
        label: '线下活动',
        value: 2
      }
    ]
    const backToTop = () => {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
    const onLoad = () => {
      queryActivityByType({
        status: active.value,
        type: activityType.value,
        ...reviewPageData
      }).then((r: any) => {
        if (state.finished) {
          return
        }
        if (state.refreshing) {
          activityList.value = []
          state.refreshing = false
        }
        activityList.value.push(...r.records)
        reviewPageData.current += 1
        if (activityList.value.length >= r.total) {
          state.finished = true
        }
        state.loading = false
      })
    }
    onLoad()
    const refreshActivity = () => {
      activityList.value = []
      state.finished = false
      reviewPageData.current = 1
      state.loading = true
      onLoad()
    }
    const getList = () => {
      backToTop()
      activityList.value = []
      state.finished = false
      reviewPageData.current = 1
      state.loading = true
      onLoad()
    }
    return {
      title,
      active,
      activityType,
      dateList,
      activityList,
      getList,
      imgUrls,
      handleDetail,
      state,
      onLoad,
      refreshActivity
    }
  }
}
