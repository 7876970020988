
import { computed, ref } from 'vue'
import router from '@/router'
import { courseSeriesList, fetchCourseSeries } from '@/states/couseSeries'
import { productList, fetchProductList } from '@/states/sales'
import { changeShareInfo } from '@/utils/wechat'

export default {
  setup: () => {
    const search = ref('')
    fetchProductList(3)
    fetchCourseSeries()
    changeShareInfo({
      title: process.env.VUE_APP_PAGE_TITLE,
      desc: process.env.VUE_APP_PAGE_DESC,
      link: window.location.href,
      imgUrl: process.env.VUE_APP_SHARE_IMG
    })
    return {
      courseSeriesList,
      productList,
      search,
      gotoSearchPage: () => {
        router.push('/search')
      },
      gotoDescriptionPage: () => {
        router.push('/description')
      },
      toProductList: () => {
        router.push('/sales/product/list')
      },
      onlineCourseSeries: computed(() => courseSeriesList.value.filter(c => c.type === 0)),
      offlineCourseSeries: computed(() => courseSeriesList.value.filter(c => c.type === 1))
    }
  }
}
