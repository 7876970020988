
import useCourseReviews from '../useCourseReviews'
import { defineComponent, PropType, toRefs, ref } from 'vue'
import { CourseSeriesDetail, Page } from 'momai'
import CommentsItem from '../pingluncomponent/CommentsItem.vue'

export default defineComponent({
  components: {
    CommentsItem
  },
  props: {
    courseSeriesDetail: {
      type: Object as PropType<CourseSeriesDetail>,
      required: true
    },
    reviewPageData: {
      type: Object as PropType<Page>,
      required: true
    }
  },
  setup: (props, { emit })  => {
    const { courseSeriesDetail } = toRefs(props)
    return {
      ...useCourseReviews({
        courseSeriesDetail,
        reviewPageData: props.reviewPageData
      }),
      showpopup: ()=> {
        emit('showpopup')
      }
    }
  }
})
