import { ref, reactive } from 'vue'
import { api } from '@/api/useAxios'
import { CourseSeriesDetail, Page, createCourseSeriesDetail } from 'momai'
import { changeShareInfo } from '@/utils/wechat'
import { getUserId } from '@/utils/auth'

export default ({ courseSeriesId }: {
  courseSeriesId: string | string[];
}) => {
  const courseSeriesAllInfo = ref<CourseSeriesDetail>(createCourseSeriesDetail())
  const reviewPageData = reactive<Page>({
    current: 1,
    size: 10
  })

  const fetchDetail = () => {
    api.post('/course/info/detail', {
      ...reviewPageData,
      courseId: courseSeriesId
    }).then(r => {
      courseSeriesAllInfo.value = r
      const c = courseSeriesAllInfo.value
      // changeShareInfo({
      //   title: c.name,
      //   desc: c.desc,
      //   link: `${process.env.VUE_APP_REDIRECT_URI}/course-series/course-detail/${courseSeriesId}?referrer=${getUserId()}&`,
      //   imgUrl: c.img
      // })
    })
  }

  fetchDetail()

  return {
    courseSeriesAllInfo,
    reviewPageData,
    fetchDetail
  }
}
