
import UserList from '../UserList.vue'

export default {
  components: { UserList },

  setup: () => {
    return {
    }
  }
}
