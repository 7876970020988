
import { ref, reactive } from 'vue'
import MomaiSelectTitle from '@/components/Momai/MomaiSelectTitle.vue'
import { api } from '@/api/useAxios'
import CourseList from '@/views/course-series/components/CourseList.vue'
import { Ref, UnwrapRef } from '@vue/reactivity'
import { Page, Tag, CourseSeries } from 'momai'
import { queryCourseList } from '@/api/entity/course-series'
import { useRouter } from 'vue-router'
import { selectByType } from '@/api/entity/tag'
import { getCompanyId } from '@/utils/auth'

interface ImgProps {
    id: string;
    imgUrl: string;
    imgLink: string;
}

export default {
  components: {
    MomaiSelectTitle,
    CourseList
  },
  setup: () => {
    const courseList = ref<CourseSeries[]>([])
    const courseTypeList = ref<Tag[]>([])
    const router = useRouter()
    const active = ref('0')
    const courseType = ref(0)
    if(router.currentRoute.value.query.id){
      courseType.value = Number(router.currentRoute.value.query.id)
    }
    const state = reactive({
      loading: false,
      finished: false,
      refreshing: false
    })
    const reviewPageData = reactive<Page>({
      current: 1,
      size: 10
    })
    const imgUrls = reactive<ImgProps[]>([])
    api.post('/companybanner/list',{companyId: getCompanyId()}).then(resp => {
      const result = resp.map((item: any) => {
        const img: ImgProps = { id: '', imgUrl: '', imgLink: '' }
        img.id = item.id
        img.imgUrl = item.url
        img.imgLink = item.linkUrl
        return img
      })
      imgUrls.push(...result)
    })

    const handleDetail = (index: number) => {
      const linkUrl: string = imgUrls[index].imgLink
      if (linkUrl) window.location.href = linkUrl
    }
    const tabList = [
      {
        id: 1,
        label: '线上课程',
        value: 0
      },
      {
        id: 2,
        label: '线下课程',
        value: 1
      }
    ]
    const activeTab: Ref<UnwrapRef<number>> = ref(0)
    const onLoad = () => {
      if (courseTypeList.value.length < 1) {
        courseList.value = []
        selectByType(2).then(r => {
          courseTypeList.value = r
          courseType.value = courseType.value !== 0 ? courseType.value : r[0].id
          queryCourseList({
            courseType: courseType.value.toString(),
            sortType: active.value,
            type: activeTab.value,
            ...reviewPageData,
            companyId:getCompanyId()
          }).then((r: any) => {
            if (state.finished) {
              return
            }
            if (state.refreshing) {
              courseList.value = []
              state.refreshing = false
            }
            courseList.value.push(...r.records)
            reviewPageData.current += 1
            if (courseList.value.length >= r.total) {
              state.finished = true
            }
            state.loading = false
          })
        })
      } else {
        queryCourseList({
          courseType: courseType.value.toString(),
          sortType: active.value,
          type: activeTab.value,
          ...reviewPageData,
          companyId:getCompanyId()
        }).then((r: any) => {
          if (state.finished) {
            return
          }
          if (state.refreshing) {
            courseList.value = []
            state.refreshing = false
          }
          courseList.value.push(...r.records)
          reviewPageData.current += 1
          if (courseList.value.length >= r.total) {
            state.finished = true
          }
          state.loading = false
        })
      }
    }
    const backToTop = () => {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
    onLoad()
    const refreshCourse = () => {
      state.finished = false
      reviewPageData.current = 1
      state.loading = true
      onLoad()
    }
    const getList = () => {
      backToTop()
      courseList.value = []
      state.finished = false
      reviewPageData.current = 1
      state.loading = true
      onLoad()
    }
    const changeTab = (_activeTab: number) => {
      activeTab.value = _activeTab
      getList()
    }
    return {
      active,
      tabList,
      courseList,
      courseTypeList,
      courseType,
      imgUrls,
      handleDetail,
      changeTab,
      getList,
      state,
      onLoad,
      refreshCourse
    }
  }
}
