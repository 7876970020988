
import { useRoute } from 'vue-router'
import { ref, watch } from 'vue'
import { Course } from 'momai'
import { listByCourseSeriesId } from '@/api/entity/course'
import { activeTab, detailCourseSeries, detailCourseSeriesId, courseSeriesPrice } from './state'
import Pay from '../Pay.vue'
import { useHasBoughtCourseSeries } from '@/api/user/student'
import { showtip } from '@/views/sales/product/useShowTip'
import { changeShareInfo } from '@/utils/wechat'
import { getUserId } from '@/utils/auth'

export default {
  components: { Pay },

  setup: () => {
    const route = useRoute()
    const { courseSeriesId } = route.params
    const pay = ref<typeof Pay | null>(null)
    const courseList = ref<Course[]>([])
    const fetchCourseList = () => {
      const cs = detailCourseSeries.value
      listByCourseSeriesId(Number(courseSeriesId)).then(list => {
        courseList.value = list
      })
      changeShareInfo({
        title: cs.courseName,
        desc: '"无练习，不演讲" \r\n魔脉"师徒"打卡训练',
        imgUrl: cs.img,
        link: `${process.env.VUE_APP_REDIRECT_URI}/course-series-list/${cs.id}?referrer=${getUserId()}`
      })
    }

    detailCourseSeriesId.value = Number(courseSeriesId)
    if (detailCourseSeries.value) {
      fetchCourseList()
    }

    watch(detailCourseSeries, fetchCourseList)

    return {
      pay,
      showtip,
      activeTab,
      courseSeriesPrice,
      couseSeriesDetail: detailCourseSeries,
      courseList,
      ...useHasBoughtCourseSeries(Number(courseSeriesId)),
      openPay: () => {
        const payInstance = pay.value
        if (payInstance) {
          payInstance.openPay()
        }
      }
    }
  }
}
