
import { computed, ref, Ref, ComputedRef } from 'vue'
import { Page } from 'momai'

type FetchFunction2 = (pageData: Page, loadEnd: () => void) => void;

/**
 * 加载选项
 */
interface LoadOption {
  /**
   * 数据总条数
   */
  total2: Ref<number>;
  /**
   * @type {Page} 分页数据
   */
  pageData?: Page;
  /**
   * 需要在该函数里根据分页参数获取下一页的数据，并追加至加载列表
   *
   * @type {FetchFunction}
   * @memberof LoadOption
   */
  fetch2: FetchFunction2;
  /**
   * 需要进行滚动加载数组
   */
  list: Ref<any[]>;
}

const myLoadList = ({
  total2,
  list,
  fetch2,
  pageData = {
    size: 10,
    current: 1
  }
}: LoadOption): {
  reset2: () => void;
  loadMore2: () => void;
  finished2: ComputedRef<boolean>;
  loading2: Ref<boolean>;
} => {
  const finished2 = computed(() => total2.value >= 0 && list.value.length >= total2.value)
  const loading2 = ref(false)
  const loadMore2 = () => {
    if (finished2.value || loading2.value) {
      return
    }
    loading2.value = true
    fetch2(pageData, () => {
      loading2.value = false
    })
    pageData.current++
  }

  const reset2 = () => {
    pageData.current = 1
    total2.value = -1
    list.value = []
    loadMore2()
  }

  loadMore2()

  return {
    reset2,
    finished2,
    loading2,
    loadMore2
  }
}

export {
  LoadOption,
  FetchFunction2,
  myLoadList
}
