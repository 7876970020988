<template>
    <div class="wt-step">
     <div class='horizontal'>
        <div class="item-wrapper">
        <div class="item" :class="[{current:index <= stepId}]" v-for='index of allStep' :key="index" @tap="switchTap(index)">
            <div class="line" v-if="index < allStep"></div>
            <div :class="[{active:index <= stepId}]" v-if="type=='default'">{{index}}</div>
            <div :class="[{active:index <= stepId},{'icon-check':index <= stepId}]" v-else></div>
            </div>
        </div>
     </div>
    </div>
</template>
<script>
export default {
  name: 'wt-step',
  props: {
    allStep: {
      type: Number,
      default: 0
    },
    stepId: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: () => {
        return 'default';
      }
    }
  }
  
};
</script>

<style lang="less" rel="stylesheet/less" scoped>
.wt-step {
    .horizontal{
        background: #fff;
        width: 100%;
        overflow: hidden;
        .item-wrapper {
            display: flex;
            height: 5.5rem;
            .item{
                text-align: center;
                align-items: center;
                flex: 1;
                position: relative;
                color: #333;
                display: flex;
                justify-content: space-around;
                &.current {
                    .line {
                        background-color: rgb( 143, 220, 134);
                    }
                }
                &:first-child{
                    margin-left: -0.5rem;
                  }
                &:last-child{
                    margin-right: -0.5rem;
                  }
                p {
                    font-size: 0.7rem;
                    margin-bottom: 1.5rem;
                    line-height: 2rem;
                  }
                &.current p{
                    color: rgb( 143, 220, 134);
                  }
                .line{
                    height: 0.1rem;
                    background: #ccc;
                    width: 57%;
                    position: absolute;
                    top: 3rem;
                    border-radius: 0px;
                    left: 72%;
                    z-index: 1;
                  }
                div {
                    background: #ccc;
                    width: 2.2rem;
                    height: 2.2rem;
                    border-radius: 50%;
                    display: inline-block;
                    z-index: 2;
                    position: absolute;
                    top:2rem;
                    font-size: 0.5rem;
                    color: #a9a9a9;
                    line-height: 2.2rem;
                    &.active {
                        background-color: rgb( 143, 220, 134);
                        color: #fff;
                      }
                  }
              }
          }
      }
}

</style>