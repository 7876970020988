import { computed, Ref } from 'vue'
import { api } from '@/api/useAxios'
import { useLoadList, FetchFunction } from '@/utils/useLoadList'
import { CourseSeriesDetail, Review } from 'momai'

export default ({
  reviewPageData,
  courseSeriesDetail
}: {
  reviewPageData: {
    current: number;
    size: number;
  };
  courseSeriesDetail: Ref<CourseSeriesDetail>;
}) => {
  const reviews = computed<Review[]>(() => courseSeriesDetail.value.evaulIPage.records)
  const total = computed(() => courseSeriesDetail.value.evaulIPage.total)
  const fetch: FetchFunction = (pageData, loadEnd) => {
    if(courseSeriesDetail.value.id){
      api.post('/mmpinglun/getPjPageInfo', {
        ordId: courseSeriesDetail.value.id,
        pjType: '0',
        ...pageData
      }).then(({ records }) => {
        courseSeriesDetail.value.evaulIPage.records.push(...records)
        loadEnd()
      })
    }
  }
  const { loadMore, ...otherLoadProps } = useLoadList({
    pageData: reviewPageData,
    list: reviews,
    total,
    fetch
  })
  return {
    reviews,
    ...otherLoadProps,
    loadMoreReviews: loadMore
  }
}
