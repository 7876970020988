
import { PropType } from 'vue'
import { Course } from 'momai'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    courseList: {
      type: Array as PropType<Course[]>,
      default: () => []
    },
    isHomework: {
      type: Boolean,
      default: false
    }
  },

  setup: () => {
    return {}
  }
}
