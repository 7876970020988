import { api } from '@/api/useAxios'
import { Ref } from 'vue'
import { CourseSeriesDetail } from 'momai'
import { likeOrUnlike } from '@/api/likeOrUnlike'
import { Toast } from 'vant'

export default ({
  courseSeriesDetail
}: {
  courseSeriesDetail: Ref<CourseSeriesDetail>;
}) => {
  return {
    collectOrUncollect: () => {
      const isAdd = courseSeriesDetail.value.courseInfoScColor === '0'
      api.post('/mmshoucang/addOrCancelSc', {
        dealType: isAdd ? 'add' : 'cancel',
        type: 0,
        ord_id: courseSeriesDetail.value.id
      }).then(() => {
        courseSeriesDetail.value.courseInfoScColor = isAdd ? '1' : '0'
        courseSeriesDetail.value.courseInfoScCnt += isAdd ? 1 : -1
        //Toast.success(`${isAdd ? '' : '取消'}收藏成功`)
      })
    },
    likeOrUnlikeComment: likeOrUnlike,
    likeOrUnlikeCourse: () => likeOrUnlike(courseSeriesDetail.value)
  }
}
