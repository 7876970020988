
import { computed, defineComponent, ref } from 'vue'
import { Toast } from 'vant'
export default defineComponent({
  props: {},
  setup: (props, { emit }) => {
    const reviewText = ref('')
    const isshow = ref(false)
    const canNotSave = computed(() => {
        return !reviewText
    })
    return {
      isshow,      
      reviewText,
      canNotSave,
      sendReview: () => {
        if (!reviewText.value) {
          Toast.fail('评价内容不可为空！')
          return
        }
        emit('send', reviewText.value, () => {
          reviewText.value = ''
        })
        isshow.value = false
      },
      openpopup: () => {
        isshow.value = true
      }
    }
  }
})
