
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'MomaiSelectTitle',
  props: {
    needSelect: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    tabList: {
      type: Array,
      default: () => []
    },
    needBack: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: Number,
      default: 0
    }
  },
  setup (props, context) {
    const router = useRouter()
    const parent = { ...context }
    const changeTab = (tab: number) => {
      parent.emit('handleTab', tab)
    }
    const getImg = (imgUrl: string) => {
      return require('@/' + imgUrl)
    }
    const tabactive = props.activeTab
    return {
      tabactive,
      getImg,
      changeTab,
      goBack: () => {
        router.go(-1)
      }
    }
  }
})
