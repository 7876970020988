
import { productList, fetchProductList } from '@/states/sales'
import { fromNow } from '@/utils/format'
import { useRouter } from 'vue-router'

export default {
  setup: () => {
    const router = useRouter()
    fetchProductList(64)
    return {
      productList,
      fromNow,
      edit: (id: string) => {
        router.push('/admin/sales/detail/' + id)
      },
      gotoPage: (id: string) => {
        router.push('/sales/product/' + id)
      }
    }
  }
}
