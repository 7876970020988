<template>
  <div>
    <van-nav-bar
      v-show="layoutState.showNav"
      :title="layoutState.title"
      left-text="返回"
      left-arrow
      fixed
      right-text="主页"
      @click-left="goBack"
      @click-right="goHome"
    />
    <div>
      <router-view
        v-slot="{ Component }"
        :class="[
          'momai-main-layout', {
          'with-nav': layoutState.showNav },
          { 'with-bottom': layoutState.showBottom }
        ]"
      >
      <component :is="Component" />
      </router-view>
    </div>
    <van-tabbar
      v-model="active"
      v-show="layoutState.showBottom"
      route
    >
      <van-tabbar-item icon="home-o" to="/">
        首页
      </van-tabbar-item>

      <van-tabbar-item icon="user-o"  to="/study" :dot="isUserHasUreadMessage">
        <span>学习圈</span>
        <template #icon="props">
          <img :src="props.active ? icona : iconb" />
        </template>
      </van-tabbar-item>
      
      <van-tabbar-item
        icon="user-o"
        to="/daily"
      >
        每日打卡
      </van-tabbar-item>
      <van-tabbar-item
        icon="browsing-history-o"
        to="/findtea"
      >
        找师傅
      </van-tabbar-item>
      <van-tabbar-item
        icon="user-o"
        to="/user"
        :dot="isUserHasUreadMessage"
      >
        我的
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { ref } from 'vue'
import { layoutState, goBack } from '@/states/layout'
import router from '@/router'
import { api } from '@/api/useAxios'
import { getToken } from '@/utils/auth'

export default {
  setup: () => {
    const active = ref(0)
    const isUserHasUreadMessage = ref(false)

    if (getToken()) {
      api.get('/sysuser/hasUnreadMessage').then(r => {
        isUserHasUreadMessage.value = r
      })
    }
   
    const iconb = '/images/friend.png'
    const icona = '/images/friendactive.png'
    return {
      active,
      icona,
      iconb,
      layoutState,
      isUserHasUreadMessage,
      goBack,
      goHome: () => {
        router.push('/')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.momai-main-layout {
  position: absolute;
  width: 100vw;
  &.with-bottom {
    padding-bottom: @tabbar-height;
  }
  &.with-nav {
    padding-top: @nav-bar-height;
  }
}
</style>
