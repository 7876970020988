
import { defineComponent, PropType } from 'vue'
import { fromNow } from '@/utils/format'
import { Review } from 'momai'
export default defineComponent({
  props: {
    reviews: Array as PropType<Review[]>
  },
  setup: () => {
    return {
      fromNow
    }
  }
})
