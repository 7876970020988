
import { computed, defineComponent, onBeforeUnmount, PropType, reactive, Ref, ref, watch, watchEffect } from 'vue'
import { CourseDetail, Homework, createHomework, CourseSeriesDetail } from 'momai'
import { getUserId } from '@/utils/auth'
import { nextWorkName, save } from '@/api/entity/homework'
import { Dialog, Notify, Toast } from 'vant'
  import UploadVideo from '@/components/Momai/MomaiCommentPublish/UploadVideo.vue'
  import UploadImg from '@/components/Momai/MomaiCommentPublish/UploadImg.vue'
  import UploadAudio from '@/components/Momai/MomaiCommentPublish/UploadAudio.vue'
  import UploadComment from '@/components/Momai/MomaiCommentPublish/UploadComment.vue'

  import Recorder from 'recorder-core/recorder.mp3.min'
  // import 'recorder-core/src/extensions/frequency.histogram.view'
  import 'recorder-core/src/extensions/lib.fft.js'
  import { uploadImage } from '@/api/universal/file'
  import useUploadVideo from '@/utils/useUploadVideo';

 interface DataType {
    type: string;
    uploadInfo: {
      info: string;
      showOrHide: boolean;
      delVedio: boolean;
      delIndex: number;
      delAudio: boolean;
    };
  }
  /**
   * 音频相关初始化和方法
   * @param emit
   */
  const useAudioEffect = () => {
    // 音频控制面板显示
    const audioTag = ref(false)
    const startTag = ref(true)
    const audioUrl = ref("")
    const btnText = ref('点击下方按钮开始录音')
    const duration = ref(0.00)
    // audioUrl.value = "https://img.51miz.com/preview/sound/00/27/03/51miz-S270339-D4B89C04.mp3"
    const recorder = Recorder({
      type: 'mp3',
      bitRate: 16,
      sampleRate: 16000,
      onProcess: function (buffers: any, powerLevel: any, bufferDuration: any, bufferSampleRate: any){
        duration.value = Number((bufferDuration / 1000).toFixed(2))
      }
    })

    const showAudioControls = () => {
      if (audioUrl.value) {
        Dialog.confirm({
          title: '确认',
          message: '已存在录音，是否需要删除重新录音？'
        }).then(() => {
          audioUrl.value = ''
          audioTag.value = !audioTag.value
        })
      } else {
        audioTag.value = !audioTag.value
      }
    }

    const openRecorder = () => new Promise((resolve) => {
      recorder.open(() => {
        resolve(true)
        startTag.value = false
      }, (msg: string, isNotAllow: boolean) => {
        if (isNotAllow) {
          Toast.fail('打开录音失败！')
        }
      })
    })
    const resetRecorder = () => {
      recorder.close()
      btnText.value = '点击下方按钮开始录音'
      startTag.value = true
    }
    const delAudio = (url: Ref) => {
      url.value = ''
    }
    // 开始录音
    const startRecord = () => {
      if (audioUrl.value) {
        Dialog.confirm({
          title: '确认',
          message: '已存在录音，是否需要删除重新录音？'
        }).then(() => {
          audioUrl.value = ''
          if (recorder) {
            openRecorder().then(() => {
              recorder.start()
              btnText.value = '录音中'
            })
          }
        })
      } else {
        if (recorder) {
          openRecorder().then(() => {
            recorder.start()
            btnText.value = '录音中'
          })
        }
      }
    }

    // 结束录音
    const endRecord = () => {
      recorder.stop((blob: Blob) => {
        const uploadDirectory = 'mmComment-audio'
        uploadImage(uploadDirectory, new File([blob], '录音.mp3')).then(r => {
          audioUrl.value = r
        })
        resetRecorder()
      }, (msg: string) => {
        resetRecorder()
        Toast.fail('录音失败:' + msg)
      })
    }

    return {
      recorder,
      audioUrl,
      resetRecorder,
      delAudio,
      showAudioControls,
      audioTag,
      startTag,
      startRecord,
      endRecord,
      duration
    }
  }

  /**
   * 视频上传
   * @author yd
   */
  const useVedioEffect = () => {
    // 视频上传
    const videoUploader = ref<HTMLInputElement | null>(null)
    const uploadDirectory = 'mmComment-video'
    const commentVideoInfo = reactive({
      videoUrl: '',
      coverUrl: '',
    })
    // 删除视频
    const delVedio = (videoInfo: any) => {
      videoInfo.videoUrl = ''
      videoInfo.coverUrl = ''
    }
    // 上传视频
    const commentVideo = useUploadVideo({
      picker: videoUploader,
      uploadDirectory,
      uploadFinished: (videoUrl, coverUrl) => {
        commentVideoInfo.videoUrl = videoUrl.value
        commentVideoInfo.coverUrl = coverUrl.value
      }
    })
    return { videoUploader, commentVideoInfo, delVedio, commentVideo}
  }

  /**
   * 图片上传
   * @param showTag 样式判断标识
   * @param showTagMid 样式判断标识
   * @param showTagMax 样式判断标识
   * @author yd
   */
  const useImgEffect = (showTag: Ref, showTagMid: Ref, showTagMax: Ref) => {
    // 图片上传
    const imgs = ref<string[]>([])
    const beforeRead = (file: File) => {
      const uploadDirectory = 'mmComment-img'
      if (imgs.value.length >= 9) {
        Toast.fail("最多只能添加9张照片")
        return
      }
      uploadImage(uploadDirectory, file).then(r => {
        imgs.value.push(r)
        // useUtilEffect().imgLen4ShowTag(imgs, showTag, showTagMid, showTagMax)
      })
    }
    return { imgs, beforeRead}
  }

  /**
   * 评论提交
   * @param props 属性值
   * @param imgs 图片数组
   * @param commentVideoInfo 视频对象信息
   * @param info 标签信息
   * @param emit 主要用来收集信息
   * @author yd
   */
  const usePublishEffect = (props: any, audio: Ref, imgs: Ref, commentVideoInfo: any, info: any, emit: Function) => {
    const params = {
      info: '',
      pImg: '',
      pVideo: '',
      pVideoCover: '',
      pVoice: '',
      status: "0",
      type: props.type,
      ordId: props.ordId,
      userId: props.userId
    }
    // 提交评论
    const publish = () => {
      params.pImg = imgs.value.join(',')
      params.pVideo =  commentVideoInfo.videoUrl
      params.pVideoCover = commentVideoInfo.coverUrl
      params.info = info.value
      params.pVoice = audio.value
      emit('handleSubmit', params)
    }
    return { publish }
  }

  /**
   * 处理业务回调
   * @param vedioInfo 视频信息
   * @param audioUrl 音频信息
   * @param imgs 图片数组
   * @param info 标签信息
   * @param showTag 样式判断标识
   * @param showTagMid 样式判断标识
   * @param showTagMax 样式判断标识
   * @author yd
   */
  const useHandleEffect = (vedioInfo: any, audioUrl: Ref, info: Ref, imgs: Ref, showTag: Ref, showTagMid: Ref, showTagMax: Ref) => {
    const handleData = ( data: Partial<DataType> ) => {
      switch (data?.type) {
        case 'video':
          if (data.uploadInfo?.delVedio) {
            const { delVedio } = useVedioEffect();
            delVedio(vedioInfo)
          }
          break;
        case 'audio':
          if (data.uploadInfo?.delAudio) {
            const { delAudio } = useAudioEffect();
            delAudio(audioUrl)
          }
          break;
        case 'text':
          info.value = data.uploadInfo?.info || ''
          // 根据评论标签的伸缩控制.opt-show的显示
          showTag.value = !data.uploadInfo?.showOrHide
          break;
        case 'img':
          const delIndex = Number(data.uploadInfo?.delIndex)
          // 删除对应的图片
          imgs.value.splice(delIndex, 1)
          // const { imgLen4ShowTag } = useUtilEffect()
          // imgLen4ShowTag(imgs, showTag, showTagMid, showTagMax)
          break;
      }
    }

    return { handleData }
  }


export default defineComponent({
  components: { UploadVideo,UploadAudio,UploadImg,UploadComment },
  props: {
    courseDetail: {
      type: Object as PropType<CourseDetail>,
      required: false
    },
    courseSeriesDetail: {
      type: Object as PropType<CourseSeriesDetail>,
      required: true
    },
  },
  setup: (props, { emit }) => {
    const isshow = ref(false)
    const homework = ref<Homework>(createHomework({
      userId: Number(getUserId())
    }))
    const changename = () => {
      nextWorkName(Number(props.courseDetail?.id)).then(r => {
        homework.value.workName = r
      })
      homework.value.courseId = Number(props.courseDetail?.id)
    }
    //watch(()=>props.courseDetail,()=>changename())
    const uploadDirectory = `homework-user-${getUserId()}`
    const resetInfo = () =>{
      homework.value = createHomework({
        userId: Number(getUserId())
      })
      changename()
    }
    const canNotSave = computed(() => {
      const hw = homework.value

      return !info.value && !commentVideoInfo.videoUrl && !commentVideoInfo.coverUrl && !audioUrl.value && !imgs.value.join(',')
      //return false
    })

    // 控制中间空白部分的样式
      const showTag = ref(true)
      const showTagMid = ref(false)
      const showTagMax = ref(false)
      // 文字评论
      const info = ref('')
    // 音频上传
      let { recorder, audioUrl, showAudioControls, audioTag, startTag, startRecord, endRecord, duration} =  useAudioEffect()
      // 组件销毁，关闭录音
      onBeforeUnmount( () => {
        recorder.close()
      })

      // 视频上传
      const { videoUploader, commentVideoInfo, delVedio, commentVideo } = useVedioEffect()

      // 图片上传
      const { imgs, beforeRead } = useImgEffect(showTag, showTagMid, showTagMax)

      // 评论提交
      const { publish } = usePublishEffect(props, audioUrl, imgs, commentVideoInfo, info,  emit)

      // 回调数据处理
      const { handleData } = useHandleEffect(commentVideoInfo, audioUrl, info, imgs, showTag, showTagMid, showTagMax)

    const isself = ref(false)
    return {
      isself,
      audioTag,
      showAudioControls,
      startTag,
      endRecord,
      startRecord,
      duration,
      handleData,
      publish,
      showTag,
      showTagMid,
      showTagMax,
      videoUploader,
      commentVideoInfo,
      commentVideo,
      delVedio,
      beforeRead,
      imgs,
      audioUrl,
      info,
      isshow,   
      homework,
      uploadDirectory,
      canNotSave,
      openpopup: () => {
        isshow.value = true
        resetInfo()
      },
      submit: () => {
        Dialog.confirm({
          title: '提示',
          message: '等待导师点评，一旦审核通过将会获得'+ props.courseDetail?.rsrv3 +'魔豆积分！'
        }).then(() => {
          homework.value.text = info.value
          homework.value.video = commentVideoInfo.videoUrl
          homework.value.videoCover = commentVideoInfo.coverUrl
          homework.value.voice = audioUrl.value
          homework.value.img = imgs.value.join(',')
          homework.value.self = isself.value ? '1' : '0'
          homework.value.companyId = props.courseSeriesDetail.companyId
          save(homework.value).then(r => {
            if (r) {
              Dialog.confirm({
                title: '提交成功',
                message: '下滑，“我的作业”里查看已提交作业'
              }).then(() => {
                // on confirm
                emit('reloadmywork')
                isshow.value = false
              }).catch(() => {
                // on cancel
              })
              // Toast.success('作业提交成功，下滑，“我的作业”里查看已提交作业')
            } else {
              Toast.fail('您未加入该班级！')
            }
            
          })
        }).catch(() => {})
      }
    }
  }
})
