
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'CourseList',
  props: {
    courseList: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const router = useRouter()
    return {
      gotoPage: (id: string) => {
        router.push('/coursedetail/' + id)
      }
    }
  }
})
